
import {
    defineComponent, reactive, ref
} from 'vue';
import List from '@/components/common/list/src/list.vue';
import { deviceApi } from '@/api';
import AddButton from '@/components/common/add-button/index.vue';
import { overlayFormData } from '@/util/system';
import { ListActionItem } from '@/components/common/list';
import removeFunc from '@/methods/remove-func';
import SetThirdDeviceDialog, { FormData } from '@/views/installer/user/components/set-control-device-dialog.vue';
import { getDeviceList } from '@/api/single/device';
import deviceStatus from '@/components/view/common/device-status/index.vue';
import HttpRequest from '@/util/axios.config';

export default defineComponent({
    components: {
        List,
        AddButton,
        SetThirdDeviceDialog,
        deviceStatus
    },
    props: {
        account: {
            type: String
        },
        // 设备类型 akuvox设备 和 第三方设备
        type: {
            type: String,
            default: 'intercom'
        },
        // 第三方设备三级菜单标识
        deviceType: {
            type: String,
            default: 'control'
        },
        personalAccountUUID: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        // 获取build和room框数据
        const updateData = ref(0);
        // 搜索条件
        const formData = reactive({
            User: props.account ? props.account : '',
            searchKey: 'MAC',
            searchValue: '',
            Brand: 'all',
            row: 10,
            page: 1,
            type: props.type,
            deviceType: props.deviceType
        });
        overlayFormData(formData);

        // 搜索选项：mac、位置
        const searchList = [{
            label: WordList.TabelPersonDeviceInHtmlMAC,
            name: 'MAC'
        }, {
            label: WordList.TabelPersonDeviceInHtmlLocation,
            name: 'Location'
        }];
        // 表头   迁移name 改成prop,增加 {
        //     name: 'op',
        //         label: WordList.CaptureListTanleAction
        // }
        const column = [{
            name: 'MAC',
            label: WordList.TabelPersonDeviceInHtmlMAC
        }, {
            name: 'Owner',
            label: WordList.TabelPersonDeviceInHtmlOwner
        }, {
            name: 'SipAccount',
            label: WordList.MulListUpdateListTanleAccount
        }, {
            name: 'Location',
            label: WordList.TabelPersonDeviceInHtmlLocation
        }, {
            name: 'Status',
            label: WordList.MulListUpdateListTanleStatus,
            type: 'customize'
        }, {
            name: 'BindingTime',
            label: WordList.PersonaldeviceListTanleBindingTime
        }];

        // 表格数据获取
        const tableData = reactive<{row: object[];detail: object[];total: number}>({
            row: [],
            detail: [],
            total: 0
        });

        // 获取第三方设备数据
        const getThirdPartyDeviceApi = () => {
            getDeviceList(
                formData,
                (res: {row: object[];detail: object[];total: number}) => {
                    tableData.row = res.row;
                    tableData.detail = res.detail;
                    tableData.total = res.total;
                    updateData.value += 1;
                }
            );
        };
        getThirdPartyDeviceApi();

        // 操作
        const isShowSetThirdDevice = ref(false);
        const operateType = ref<'add'|'edit'>('add');
        const add = () => {
            editData.MAC = '';
            editData.Location = '';
            isShowSetThirdDevice.value = true;
            operateType.value = 'add';
        };
        const editData = reactive<FormData>({
            Node: props.account ? props.account : '',
            MAC: '',
            Location: '',
            PersonalAccountUUID: props.personalAccountUUID
        });

        const edit = (item: FormData) => {
            editData.MAC = item.MAC;
            editData.Location = item.Location;
            editData.ID = item.ID;
            isShowSetThirdDevice.value = true;
            operateType.value = 'edit';
        };

        // 删除
        const deleteThirdPartyDeviceApi = (data: { ID: string }) => {
            removeFunc(WordList.TabelDeleteText, () => {
                HttpRequest.post('deletepersonaldevice', { ID: data.ID }, getThirdPartyDeviceApi);
            });
        };

        // action操作 迁移备注：这部分不用迁移 主要是为了适配删除触发点击
        const action: ListActionItem[] = [{
            type: 'edit2',
            event(data: FormData) {
                edit(data);
            },
            class: 'el-icon-my-modify modify-icon',
            title: WordList.TabelConfigInHtmlModify
        }, {
            type: 'delete2',
            event(data: { ID: string }) {
                deleteThirdPartyDeviceApi(data);
            },
            class: 'el-icon-delete delete-icon',
            title: WordList.TabelConfigInHtmlDelete
        }];

        return {
            formData,
            column,
            getThirdPartyDeviceApi,
            tableData,
            updateData,
            isShowSetThirdDevice,
            edit,
            editData,
            add,
            operateType,
            action,
            searchList
        };
    }
});
